import styled from 'styled-components'

export const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px;

  .link {
    color: #002e77;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
`
