export const headCells = [
  {
    id: 'tags',
    label: 'Tags',
  },
  {
    id: 'modified',
    label: 'Última modificação',
  },
  {
    id: 'action',
  },
]

export const tabLabels = ['Todas']
