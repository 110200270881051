import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { themeOverWrites } from './styles'

const useStyles = makeStyles(themeOverWrites)

export default function PageHeader() {
  const classes = useStyles()

  return (
    <>
      <Typography variant='h6' gutterBottom>
        <span className={classes.emoji} role='img' aria-label='checkmark'>
          📝
        </span>
        Notas
      </Typography>
    </>
  )
}
