import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  drawer: {
    isOpen: false,
  },
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleDrawer: state => {
      state.drawer.isOpen = !state.drawer.isOpen
    },
  },
})

export const { toggleDrawer } = uiSlice.actions
export default uiSlice.reducer
