import React from 'react'

import Default from 'templates/default'

import ListItems from './components/ListItems'
import PageHeader from './components/PageHeader'

export default function ListDetails() {
  return (
    <Default CustomHeader={PageHeader}>
      <ListItems />
    </Default>
  )
}
