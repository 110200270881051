import styled from 'styled-components'

export const themeOverWrites = theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '16px 24px',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
})

export const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
`
