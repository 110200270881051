export const headCells = [
  {
    id: 'dueTime',
    label: 'Horário',
  },
  {
    id: 'dueDate',
    label: 'Dia',
  },
  {
    id: 'taskDescription',
    label: 'Descrição',
  },
  {
    id: 'action',
  },
]

export const tabLabels = []
