import { Button, Paper, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import React, { useCallback, useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import Logo from 'assets/logomaia.svg'
import CellPhoneInput from 'components/CellPhoneInput'
import InputText from 'components/InputText'
import { clearPhoneMask } from 'helpers/string'
import { getAuthToken } from 'services/auth'

import { LogoImage, themeOverWrites } from './styles'

const useStyles = makeStyles(themeOverWrites)

const wppUrl =
  'https://api.whatsapp.com/send?phone=' +
  process.env.REACT_APP_NUMBER +
  '&text=Quero%20o%20c%C3%B3digo%20para%20acessar%20o%20WebApp.'

export default function LogBox() {
  const classes = useStyles()
  const { push } = useHistory()
  const mobile = useMediaQuery('(max-width:600px)')
  const [tokenNumber, setTokenNumber] = useState('')
  const { search } = useLocation()
  const useQuery = () => {
    return new URLSearchParams(search)
  }

  const numberQuery = useQuery().get('whatsapp')
  const codeQuery = useQuery().get('codigo')
  const nextUrl = useQuery().get('pagina')

  useEffect(() => {
    if (numberQuery && codeQuery) {
      getAuthToken({
        user: numberQuery,
        token: codeQuery,
      }).then(res => {
        if (res?.access_token) {
          if (nextUrl) {
            push(`${nextUrl}`)
          } else {
            push('/dashboard')
          }
        }
      })
    }
  }, [])

  const handleTokenInputChange = useCallback(e => {
    e.currentTarget.maxLength = 4
    let { value } = e.currentTarget
    value = value.replace(/\D/g, '')
    e.currentTarget.value = value

    setTokenNumber(value)
  }, [])

  const onSubmit = values => {
    getAuthToken({
      user: `55${clearPhoneMask(values.mobileNumber)}`,
      token: tokenNumber,
    }).then(res => {
      if (res?.access_token) {
        push('/dashboard')
      }
    })
  }

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      mobileNumber: '',
      authCode: '',
    },
    onSubmit,
  })

  return (
    <Paper className={classes.paper}>
      <LogoImage height='40' width='156' src={Logo} alt='' />
      <h1>&nbsp;&nbsp;Carregando / Loading ...&nbsp;&nbsp;</h1>
    </Paper>
  )
}
