export const themeOverWrites = () => ({
  root: {
    flexGrow: 1,
    overflowX: 'auto',
  },
  emoji: {
    marginRight: '10px',
  },
  '@media screen and (max-width: 600px)': {
    root: {
      flexGrow: 0,
      width: 'calc(100vw - 24px)',
      'tr td + td': {
        width: '20px',
      },
    },
  },
})
