import styled from 'styled-components'

export const themeOverWrites = () => ({
  root: {
    flexGrow: 1,
  },
  emoji: {
    marginRight: '10px',
  },
})

export const BreadCrumb = styled.ul`
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;
`

export const BreadCrumbItem = styled.li`
  margin-left: ${({ marginLeft }) => (marginLeft ? '12px' : 'inherit')};

  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  }

  &:first-of-type {
    color: #8e8e8e;
    filter: grayscale(100%);
  }
`
