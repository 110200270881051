import { dataIdentity } from 'helpers/identities'
import HttpService from 'services/HttpService'

const WALLET_URL = process.env.REACT_APP_WALLET

export const getAllWallet = () => {
  return HttpService.withToken
    .get(WALLET_URL)
    .then(dataIdentity)
    .catch(console.log)
}

// export const getWalletItems = tags =>
//   HttpService.withToken.get(`${WALLET_URL}/itens?${tags}`).then(dataIdentity)

export const addList = name => {
  return HttpService.withToken.post(WALLET_URL, { name })
}

export const deleteWallet = tags =>
  HttpService.withToken.delete(`${WALLET_URL}?tags=${tags}`)

export const sendWallet = tags =>
  HttpService.withToken.get(`${WALLET_URL}/send?tags=${tags}`)

export const updateItem = (itemId, status) =>
  HttpService.withToken
    .put(`${WALLET_URL}/itens/${itemId}`, { done: status })
    .then(dataIdentity)

// export const addItem = (listId, item) =>
//   HttpService.withToken.post(`${WALLET_URL}/${listId}/itens`, { item })

export const deleteWalletItem = item =>
  HttpService.withToken.delete(`${WALLET_URL}/itens/${item}`)
