import { dataIdentity } from 'helpers/identities'
import HttpService from 'services/HttpService'

const REMINDER_URL = process.env.REACT_APP_REMINDER

export const getAllReminder = () => {
  return HttpService.withToken
    .get(REMINDER_URL)
    .then(dataIdentity)
    .catch(console.log)
}

export const deleteReminderItem = item =>
  HttpService.withToken.delete(`${REMINDER_URL}/itens/${item}`)
