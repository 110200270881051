import { Box } from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import PropTypes from 'prop-types'
import React from 'react'

function TabPanel({ children, value, index }) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

export default function TabsWrapper({ tabs, contents }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
      >
        {tabs.map(item => {
          return <Tab key={item} label={item} />
        })}
      </Tabs>
      {contents.map(({ content }, idx) => {
        return (
          <TabPanel key={idx} value={value} index={idx}>
            {content}
          </TabPanel>
        )
      })}
    </>
  )
}

TabsWrapper.propTypes = {
  tabs: PropTypes.array.isRequired,
  contents: PropTypes.array.isRequired,
}
