export const localDate = dateStr => {
  return new Date(dateStr).toLocaleDateString('pt-br', {
    hour: 'numeric',
    minute: 'numeric',
  })
}

export const localDateString = dateStr => {
  return new Date(dateStr).toLocaleDateString('pt-br', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

export const localDateWithoutTime = dateStr => {
  return new Date(dateStr).toLocaleDateString('pt-br')
}
