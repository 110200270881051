import { dataIdentity } from 'helpers/identities'
import HttpService from 'services/HttpService'

const LISTS_URL = process.env.REACT_APP_LISTS

export const getAllLists = () => {
  return HttpService.withToken
    .get(LISTS_URL)
    .then(dataIdentity)
    .catch(console.log)
}

export const addList = name => {
  return HttpService.withToken.post(LISTS_URL, {
    name,
  })
}

export const deleteList = tags =>
  HttpService.withToken.delete(`${LISTS_URL}?tags=${tags}`)

export const sendList = tags =>
  HttpService.withToken.get(`${LISTS_URL}/send?tags=${tags}`)

export const getListItems = tags =>
  HttpService.withToken.get(`${LISTS_URL}/itens?${tags}`).then(dataIdentity)

export const updateItem = (itemId, status) =>
  HttpService.withToken
    .put(`${LISTS_URL}/itens/${itemId}`, {
      done: status,
    })
    .then(dataIdentity)

export const addItem = (listId, item) =>
  HttpService.withToken.post(`${LISTS_URL}/${listId}/itens`, {
    item,
  })

export const deleteListItem = item =>
  HttpService.withToken.delete(`${LISTS_URL}/itens/${item}`)
