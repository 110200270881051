import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import ImageIcon from '@material-ui/icons/Image'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import VideocamIcon from '@material-ui/icons/Videocam'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  align-items: center;
  display: flex;

  .name {
    margin-left: 10px;
  }
`

export function GetFileIcon(type) {
  const iconTypes = {
    document: (
      <Wrapper>
        <InsertDriveFileIcon />
        <div className='name'>Documento</div>
      </Wrapper>
    ),
    image: (
      <Wrapper>
        <ImageIcon />
        <div className='name'>Imagem</div>
      </Wrapper>
    ),
    video: (
      <Wrapper>
        <VideocamIcon />
        <div className='name'>Vídeo</div>
      </Wrapper>
    ),
    audio: (
      <Wrapper>
        <AudiotrackIcon />
        <div className='name'>Audio</div>
      </Wrapper>
    ),
    voice: (
      <Wrapper>
        <AudiotrackIcon />
        <div className='name'>Audio</div>
      </Wrapper>
    ),
  }

  return iconTypes[type]
}

export const FILE_TYPES = {
  image: 'Imagem',
  document: 'Documento',
  video: 'Vídeo',
  audio: 'Audio',
  voice: 'Audio',
}
