import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  font-size: larger;
  margin-top: 24px;

  > svg {
    margin-right: 4px;
  }

  button {
    background-color: transparent;
    color: #ffffff;
    border: none;
    margin-left: 8px;
    outline: transparent;
  }
`

export const SnackbarContent = styled.div`
  background-color: #53af50;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;

  svg {
    margin-right: 4px;
  }
`
