import clientSideCookies from 'js-cookie'
import React from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import { getAuthToken } from 'services/auth'

export default function WithAuth(Component) {
  return function () {
    const authToken = clientSideCookies.get('access_token')
    const params = useParams()
    const { push } = useHistory()

    if (params.number && params.code) {
      getAuthToken({
        user: params.number,
        token: params.code,
      }).then(res => {
        console.log('res', res)
        if (!res) {
          push('/')
          return
        }

        return <Component />
      })
    }

    if (authToken) {
      return <Component />
    }

    return <Redirect exact to='/' />
  }
}
