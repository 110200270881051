import styled from 'styled-components'

import { ZINDEX_LEVELS } from '../../enum/styles'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .background-image {
    width: 100%;
    height: auto;
  }

  &:before {
    background: transparent linear-gradient(180deg, #002e77 0%, #6e1bf8 100%) 0%
      0% no-repeat padding-box;
    content: '';
    display: block;
    height: 100%;
    opacity: 0.73;
    position: absolute;
    width: 100%;
    z-index: ${ZINDEX_LEVELS.INTERMEDIATE};
  }
`

export const QRCodeBoxWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: ${ZINDEX_LEVELS.HIGH};
`
