import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import DeleteConfirmation from 'components/DeleteConfirmation'
import { getNoteItems, deleteNoteItem } from 'services/notes'

import NoteItem from '../NoteItem'

import { EmptyStateWrapper, TagContainer } from './styles'

function NoteItems() {
  const params = useParams()
  const [noteItems, setNoteItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')
  const { tags: noteTags } = params

  const tags = Array.from(noteTags.substr(5).split(','))

  const update = noteTags => () => {
    console.log(noteTags)
  }

  const updateNoteItem = update(noteTags)

  const handleDelete = () => {
    setDeleteDialogOpen(false)
    const updatedNote = noteItems.filter(item => item.id !== idToDelete)
    console.log(noteTags, idToDelete)
    deleteNoteItem(idToDelete).then(() => {
      setNoteItems(updatedNote)
    })
    // setNoteItems(updatedNote)
  }

  const handleDeleteConfirmation = id => () => {
    setDeleteDialogOpen(true)
    setIdToDelete(id)
  }

  useEffect(() => {
    getNoteItems(noteTags).then(res => {
      setNoteItems(res)
      setIsLoading(false)
    })
    // setNoteItems(noteItemsTest)
    // setIsLoading(false)
  }, [])

  return (
    <>
      {' '}
      {!isLoading && noteItems.length === 0 ? (
        <EmptyStateWrapper>
          <Typography variant='body1' gutterBottom>
            Você não tem nenhuma nota com as tags{' '}
            {tags.map(tag => (
              <TagContainer key={tag}>{tag}</TagContainer>
            ))}{' '}
          </Typography>
        </EmptyStateWrapper>
      ) : (
        <>
          <ul>
            {' '}
            {noteItems.map(({ id, note }) => {
              return (
                <NoteItem
                  note={note}
                  key={id}
                  id={id}
                  update={updateNoteItem}
                  edit={updateNoteItem}
                  exclude={handleDeleteConfirmation(id)}
                />
              )
            })}{' '}
          </ul>
          <DeleteConfirmation
            dialogOpen={deleteDialogOpen}
            setDialogOpen={setDeleteDialogOpen}
            handleDelete={handleDelete}
            deleteTitle={'Deletar item?'}
          />
        </>
      )}{' '}
    </>
  )
}

export default NoteItems
