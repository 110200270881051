import React from 'react'

import loginBackground from 'assets/login_background.png'
import Footer from 'components/Footer'
import PhoneNumber from 'components/PhoneNumber'

import QRCodeBox from './components/QRCodeBox'
import { QRCodeBoxWrapper, Container } from './styles'

export default function Login() {
  return (
    <Container>
      <QRCodeBoxWrapper>
        <QRCodeBox />
        <PhoneNumber />
      </QRCodeBoxWrapper>
      <Footer />
      <img
        className='background-image'
        height='1080'
        width='1920'
        src={loginBackground}
        alt='Pessoas sentadas olhando dispositivos móveis'
      />
    </Container>
  )
}
