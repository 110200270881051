import styled from 'styled-components'

export const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px;

  .link {
    color: #002e77;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
`

export const TagContainer = styled.div`
  display: inline-block;
  background-color: lightgray;
  margin-right: 8px;
  padding: 4px 8px;
  border-radius: 8px;
`
