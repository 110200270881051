import { Snackbar, Fade } from '@material-ui/core'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import React, { useState, useCallback } from 'react'
import { FiCheckCircle } from 'react-icons/fi'

import { Container, SnackbarContent } from './styles'

export default function PhoneNumber() {
  const [snack, setSnack] = useState({
    open: false,
    Transition: Fade,
  })

  const onCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(process.env.REACT_APP_NUMBER_LABEL)

    toggleSnack()
  }, [])

  const toggleSnack = useCallback(() => {
    setSnack(prevState => ({
      ...prevState,
      open: !snack.open,
    }))
  }, [])

  return (
    <>
      <Snackbar
        open={snack.open}
        onClose={() =>
          setSnack(prevState => ({
            ...prevState,
            open: false,
          }))
        }
        autoHideDuration={2000}
        TransitionComponent={snack.Transition}
        key={snack.Transition.name}
      >
        <SnackbarContent>
          <FiCheckCircle />
          Número copiado para sua área de transferência!
        </SnackbarContent>
      </Snackbar>
    </>
  )
}
