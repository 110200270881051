import { configureStore } from '@reduxjs/toolkit'

import uiReducer from './reducers/ui'

export default configureStore({
  reducer: {
    ui: uiReducer,
  },
  devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
})
