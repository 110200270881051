import PropTypes from 'prop-types'
import React from 'react'

import { Wrapper } from './styles'

function FileWrapper({ fileType, fileSource }) {
  return (
    <Wrapper>
      {fileType === 'image' && (
        <img src={fileSource} alt='imagem selecionada' width='100%' />
      )}
    </Wrapper>
  )
}

FileWrapper.propTypes = {
  fileType: PropTypes.string.isRequired,
  fileSource: PropTypes.string.isRequired,
}

export default FileWrapper
