export const headCells = [
  {
    id: 'file',
    label: 'Tipo de arquivo',
  },
  {
    id: 'tags',
    label: 'Tags',
  },
  {
    id: 'created_at',
    label: 'Enviado em',
  },
  {
    id: 'size',
    label: 'Tamanho do arquivo',
  },
  {
    id: 'action',
  },
]

export const tabLabels = ['Todas', 'Fotos', 'Vídeos', 'Documentos', 'Áudios']

export const filterRows = rows => {
  return {
    all: rows,
    document: rows.filter(item => item.type === 'document'),
    image: rows.filter(item => item.type === 'image'),
    video: rows.filter(item => item.type === 'video'),
    audio: rows.filter(item => item.type === 'audio' || item.type === 'voice'),
  }
}
