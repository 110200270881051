import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import React from 'react'

export default function Actions({ exclude }) {
  return (
    <>
      <IconButton onClick={exclude} aria-label='delete'>
        <DeleteIcon />
      </IconButton>
    </>
  )
}

Actions.propTypes = {
  exclude: PropTypes.func,
}
