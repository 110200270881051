export const themeOverWrites = () => ({
  gridItem: {
    alignItems: 'center',
    display: 'flex',
    padding: '16px',
    '&:hover': { color: 'blue', cursor: 'pointer' },
  },
  pageTitle: {
    alignItems: 'center',
    display: 'flex',
  },
  emoji: {
    marginRight: '10px',
  },
})
