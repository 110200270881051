import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { toggleDrawer } from 'redux/reducers/ui'

import Logo from '../logo'
import { ProfileIcon } from '../ProfileIcons'

import { themeOverWrites } from './styles'

const useStyles = makeStyles(theme => themeOverWrites(theme))

const menuItems = [
  {
    label: 'Dashboard',
    icon: '🏠',
    url: '/dashboard',
  },
  {
    label: 'Listas',
    icon: '✔️',
    url: '/listas',
  },
  {
    label: 'Mídias',
    icon: '📂️',
    url: '/midias',
  },
  {
    label: 'Notas',
    icon: '📝',
    url: '/notas',
  },
  {
    label: 'Carteira',
    icon: '💰',
    url: '/carteira',
  },
  {
    label: 'Lembretes',
    icon: '⏰',
    url: '/lembretes',
  },
]

export default function Header() {
  const mobile = useMediaQuery('(max-width:600px)')
  const classes = useStyles()
  const open = useSelector(state => state.ui.drawer.isOpen)
  const dispatch = useDispatch()
  const history = useHistory()

  const goTo = url => () => {
    if (mobile) {
      history.push(url)
      toggle()
    } else {
      history.push(url)
    }
  }

  const toggle = () => {
    dispatch(toggleDrawer())
  }

  return (
    <>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={toggle}
            edge='start'
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Logo onClick={goTo('/dashboard')} />
        </Toolbar>
        <div className={clsx(classes.profileIcons)}>
          <ProfileIcon />
        </div>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} onClick={toggle}>
          <IconButton>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <List>
          {menuItems.map(item => {
            return (
              <ListItem onClick={goTo(item.url)} button key={item.label}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            )
          })}
        </List>
      </Drawer>
    </>
  )
}
