import styled from 'styled-components'

export const themeOverWrites = () => ({
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '30px 00px',
  },
  form: {
    width: '55%',
  },
  sbmt: {
    margin: '10px 0',
    width: '100%',
  },
  input: {
    marginBottom: 25,
    width: '100%',
  },
  text: {
    textAlign: 'center',
  },
})

export const LogoImage = styled.img`
  margin-bottom: 40px;
`
