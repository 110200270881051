import styled from 'styled-components'

import { ZINDEX_LEVELS } from '../../enum/styles'

export const Container = styled.div`
  display: flex;
  z-index: ${ZINDEX_LEVELS.HIGH};
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 92%;
  margin: 0 4%;
  padding: 1rem 0;
  border-top: 1px solid #ffffff;

  > a {
    color: inherit;
    font-weight: bold;
    margin-left: auto;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  span {
    display: inline-block;
  }

  @media (max-width: 600px) {
    font-size: 0.8rem;
    flex-direction: column-reverse;
    border-top: none;
    text-align: center;

    > a {
      margin-left: unset;
      margin-bottom: 1em;
    }
  }
`
