import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import ChipInput from 'material-ui-chip-input'
import PropTypes from 'prop-types'
import React from 'react'

import { addTagToFile, removeTagFromFile } from 'services/tags'

import { themeOverWrites, Title } from './styles'

const useStyles = makeStyles(themeOverWrites)

function FileTags({ tags, fileId }) {
  const classes = useStyles()
  const [chips, setChips] = React.useState(tags.map(item => item.tag))

  const handleDelete = chipToDelete => {
    setChips(chips => chips.filter(chip => chip !== chipToDelete))
    removeTagFromFile(fileId, chipToDelete)
  }

  const addChip = chipToAdd => {
    setChips(prevState => [...prevState, chipToAdd])
    addTagToFile(fileId, chipToAdd)
  }

  return (
    <Paper className={classes.root}>
      <Title>Tags:</Title>
      <ChipInput
        fullWidth
        variant='outlined'
        newChipKeyCodes={[32]}
        value={chips}
        onAdd={addChip}
        onDelete={handleDelete}
      />
    </Paper>
  )
}

FileTags.propTypes = {
  tags: PropTypes.array.isRequired,
  fileId: PropTypes.string.isRequired,
}

export default FileTags
