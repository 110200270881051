import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import PropTypes from 'prop-types'
import React from 'react'

function Actions({ download, exclude, sentToWhatsApp }) {
  return (
    <>
      {download && (
        <IconButton onClick={download} aria-label='download'>
          <GetAppIcon />
        </IconButton>
      )}
      <IconButton onClick={sentToWhatsApp} aria-label='send'>
        <WhatsAppIcon />
      </IconButton>
      <IconButton onClick={exclude} aria-label='delete'>
        <DeleteIcon />
      </IconButton>
    </>
  )
}

Actions.propTypes = {
  download: PropTypes.func,
  exclude: PropTypes.func,
  sentToWhatsApp: PropTypes.func,
}

export default Actions
