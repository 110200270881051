import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Header from 'components/Header'

import { themeOverWrites } from './styles'

const useStyles = makeStyles(theme => themeOverWrites(theme))

const ROOT_PATH = '/'
const QR_CODE_PATH = '/qrcode'

export default function DrawerContent({ children }) {
  const classes = useStyles()
  const open = useSelector(state => state.ui.drawer.isOpen)
  const { pathname } = useLocation()
  const forbidenPaths = [ROOT_PATH, QR_CODE_PATH]
  const renderDrawer = forbidenPaths.includes(pathname)

  return (
    <div className={classes.root}>
      {renderDrawer ? (
        <>{children}</>
      ) : (
        <>
          <Header />
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={clsx(classes.drawerHeader)} />
            {children}
          </main>
        </>
      )}
    </div>
  )
}

DrawerContent.propTypes = {
  children: PropTypes.node.isRequired,
}
