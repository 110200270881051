export const headCells = [
  {
    id: 'balanceType',
  },
  {
    id: 'value',
    label: 'Valor',
  },
  {
    id: 'title',
    label: 'Título',
  },
  {
    id: 'balanceType',
    label: 'Tipo da Transação',
  },
  {
    id: 'transactionDate',
    label: 'Data da Transação',
  },
  {
    id: 'action',
  },
]

export const tabLabels = []
