import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import clientSideCookies from 'js-cookie'
import React from 'react'
import { useHistory } from 'react-router-dom'

import * as S from './styles'

export function ProfileIcon() {
  const history = useHistory()

  const logout = () => {
    clientSideCookies.remove('access_token')
    clientSideCookies.remove('refresh_token')
    history.push('/')
  }

  return (
    <S.Wrapper>
      <S.Divider />
      <S.Logout onClick={logout}>
        <ExitToAppIcon />
        <S.LogoutText>Sair</S.LogoutText>
      </S.Logout>
    </S.Wrapper>
  )
}
