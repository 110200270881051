import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Breadcrumb, TagContainer, themeOverWrites } from './styles'
const useStyles = makeStyles(themeOverWrites)

export default function PageHeader() {
  const classes = useStyles()
  const { tags: tagsParam } = useParams()
  const history = useHistory()

  const tags = Array.from(tagsParam.substr(5).split(','))

  const goBack = () => history.push('/notas')

  return (
    <Breadcrumb>
      <Typography
        onClick={goBack}
        className={classes.goback}
        variant='h6'
        gutterBottom
      >
        <span className={classes.emoji} role='img' aria-label='checkmark'>
          📝
        </span>
        Notas
      </Typography>
      <ArrowBackIosIcon fontSize='small' />
      {tags.map(tag => (
        <TagContainer key={tag}>{tag}</TagContainer>
      ))}
    </Breadcrumb>
  )
}
