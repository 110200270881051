import { dataIdentity } from 'helpers/identities'
import HttpService from 'services/HttpService'

const NOTES_URL = process.env.REACT_APP_NOTES

export const getAllNotes = () => {
  return HttpService.withToken
    .get(NOTES_URL)
    .then(dataIdentity)
    .catch(console.log)
}

export const getNoteItems = tags =>
  HttpService.withToken.get(`${NOTES_URL}/itens?${tags}`).then(dataIdentity)

export const addList = name => {
  return HttpService.withToken.post(NOTES_URL, { name })
}

export const deleteNote = tags =>
  HttpService.withToken.delete(`${NOTES_URL}?tags=${tags}`)

export const sendNote = tags =>
  HttpService.withToken.get(`${NOTES_URL}/send?tags=${tags}`)

export const updateItem = (itemId, status) =>
  HttpService.withToken
    .put(`${NOTES_URL}/itens/${itemId}`, { done: status })
    .then(dataIdentity)

export const addItem = (listId, item) =>
  HttpService.withToken.post(`${NOTES_URL}/${listId}/itens`, { item })

export const deleteNoteItem = item =>
  HttpService.withToken.delete(`${NOTES_URL}/itens/${item}`)
