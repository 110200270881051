import styled from 'styled-components'

export const themeOverWrites = () => ({
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '12px 00px 30px',
  },
  text: {
    width: '85%',
    marginBottom: '8px',
  },
  button: {
    width: '100%',
  },
})

export const GoBackContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  margin-bottom: 20px;
  margin-left: 8%;
  background-color: transparent;
  outline: transparent;
  border: none;
`

export const Content = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 20px 0 8px;
    text-align: center;
  }

  a {
    margin-bottom: 24px;
  }
`

export const ImageContainer = styled.div`
  img {
    width: 234px;
  }
`
