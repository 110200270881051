import { Badge } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import DataTable from 'components/Table'
import { localDate } from 'helpers/date'
import { getTagList } from 'services/tags'
import Default from 'templates/default'

import Tags from '../notes/components/Tags'

export default function Media() {
  const history = useHistory()
  const [tagList, setTagList] = useState([])
  const headCells = [
    {
      id: 'name',
      label: 'Nome',
    },
    {
      id: 'sent',
      label: 'Enviado',
    },
    {
      id: 'count',
      label: 'Arquivos',
    },
  ]

  const tableRows = Array.isArray(tagList)
    ? tagList.map(({ tag: name, files: count, created_at }) => {
        return {
          name,
          tags: name,
          sent: localDate(created_at),
          count: <Badge badgeContent={count} color='primary' />,
        }
      })
    : []

  const goToRowDetail = ({ tags }) => () => {
    // const tags = tagsObj.props.tagsArr
    // console.log(tagsObj)
    history.push(`/midias/detalhes/${tags}`)
  }

  useEffect(() => {
    getTagList().then(setTagList)
  }, [])

  return (
    <Default pageTitle='Mídias' unicodeEmoji='📁'>
      <DataTable
        headCells={headCells}
        tableRows={tableRows}
        goToRowDetail={goToRowDetail}
        filterRows={['id']}
        filterDataMobile={['sent']}
        unsortable
      />
    </Default>
  )
}
