import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'

import loginBackground from 'assets/login_background.png'
import Footer from 'components/Footer'
import PhoneNumber from 'components/PhoneNumber'

import LogBox from './components/LogBox'
import { LogBoxWrapper, LoginWrapper } from './styles'

export default function Login() {
  const mobile = useMediaQuery('(max-width:600px)')
  return (
    <LoginWrapper>
      <LogBoxWrapper>
        <LogBox />
        <PhoneNumber />
        <Footer />
      </LogBoxWrapper>
      {!mobile && (
        <img
          className='background-image'
          height='1080'
          width='1920'
          src={loginBackground}
          alt='Pessoas sentadas olhando dispositivos móveis'
        />
      )}
    </LoginWrapper>
  )
}
