import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import DeleteConfirmation from 'components/DeleteConfirmation'
import { getListItems, updateItem, deleteListItem } from 'services/lists'

import ListItem from '../ListItem'

import { EmptyStateWrapper } from './styles'

function ListItems() {
  const params = useParams()
  const [listItems, setListItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')
  const { tags: tagsParam } = params

  const update = () => (itemId, status) => {
    const currentItemIndex = listItems.findIndex(item => item.id === itemId)

    const updatedItem = {
      ...listItems[currentItemIndex],
      done: status,
    }

    const updatedList = listItems.map(item =>
      item.id === itemId ? updatedItem : item
    )

    updateItem(itemId, status).then(() => {
      setListItems(updatedList)
    })
  }

  const updateListItem = update()

  const handleDelete = () => {
    setDeleteDialogOpen(false)
    const updatedList = listItems.filter(item => item.id !== idToDelete)
    deleteListItem(idToDelete).then(() => {
      setListItems(updatedList)
    })
  }

  const handleDeleteConfirmation = id => () => {
    setDeleteDialogOpen(true)
    setIdToDelete(id)
  }

  useEffect(() => {
    getListItems(tagsParam).then(res => {
      setListItems(res?.itens)
      setIsLoading(false)
    })
  }, [])

  return (
    <>
      {!isLoading && listItems.length === 0 ? (
        <EmptyStateWrapper>
          <Typography variant='body1' gutterBottom>
            Sua lista ”Compras” está vazia. <br /> Para adicionar um novo item{' '}
            {/*TODO: add item on the list by clicking on span*/}
            <span className='link'>clique aqui</span> para criar um novo item.
          </Typography>
        </EmptyStateWrapper>
      ) : (
        <>
          <ul>
            {listItems.map(({ id, item, done }) => {
              return (
                <ListItem
                  name={item}
                  key={id}
                  id={id}
                  done={done}
                  update={updateListItem}
                  exclude={handleDeleteConfirmation(id)}
                />
              )
            })}
          </ul>
          <DeleteConfirmation
            dialogOpen={deleteDialogOpen}
            setDialogOpen={setDeleteDialogOpen}
            handleDelete={handleDelete}
            deleteTitle={'Deletar item?'}
          />
        </>
      )}
    </>
  )
}

export default ListItems
