import clientSideCookies from 'js-cookie'

import HttpService from 'services/HttpService'

const AUTH_URL = process.env.REACT_APP_AUTH

export const getAuthToken = ({ user, token }) => {
  return HttpService.withoutToken
    .post(`${AUTH_URL}/token`, {
      user,
      token,
    })
    .then(response => {
      const { access_token, refresh_token } = response.data
      clientSideCookies.set('access_token', access_token)
      clientSideCookies.set('refresh_token', refresh_token)
      return {
        access_token,
        refresh_token,
      }
    })
    .catch(console.log)
}
