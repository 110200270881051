import { Snackbar, Typography } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import DeleteConfirmation from 'components/DeleteConfirmation'
import DataTable from 'components/Table'
import TabsWrapper from 'components/Tabs'
import { getAllNotes, sendNote, deleteNote } from 'services/notes'
import Default from 'templates/default'

import { localDate } from '../../helpers/date'

import Actions from './components/Actions'
import PageHeader from './components/PageHeader'
import Tags from './components/Tags'
import { headCells, tabLabels } from './helpers/tableLabels'
import { EmptyStateWrapper } from './styles'

// const testNotesData = [
//   {
//     created_at: '2021-08-31 10:15:12',
//     ids: ['15'],
//     tags: ['Nota1', 'teste1'],
//     updated_at: '2021-08-31 10:15:12',
//   },
//   {
//     created_at: '2021-08-31 10:15:12',
//     ids: ['15'],
//     tags: ['Nota2', 'teste2', 'tags3'],
//     updated_at: '2021-08-31 10:15:12',
//   },
// ]

export default function Lists() {
  const history = useHistory()
  const [lists, setLists] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [tagsToDelete, setTagsToDelete] = useState('')
  const [snack, setSnack] = React.useState({
    open: false,
    Transition: Fade,
  })

  const toggleSnack = () => {
    setSnack(prevState => ({
      ...prevState,
      open: !snack.open,
    }))
  }

  const send = tags => () => {
    console.log(tags)
    sendNote(tags)
      .then(() => {
        toggleSnack()
      })
      .catch(console.log)
  }

  const handleDelete = () => {
    setDeleteDialogOpen(false)
    console.log(tagsToDelete)
    const updatedList = lists.filter(item => item.tags !== tagsToDelete)
    deleteNote(tagsToDelete).then(() => {
      setLists(updatedList)
    })
  }

  const handleDeleteConfirmation = tags => () => {
    setDeleteDialogOpen(true)
    setTagsToDelete(tags)
  }

  const tableRows = Array.isArray(lists)
    ? lists.map(({ tags, updated_at, ids }) => {
        return {
          tags: <Tags tagsArr={tags} />,
          modified: localDate(updated_at),
          action: (
            <Actions
              exclude={handleDeleteConfirmation(tags)}
              sentToWhatsApp={send(tags)}
            />
          ),
          ids,
        }
      })
    : new Array()

  const goToRowDetail = ({ tags: tagsObj }) => () => {
    const tags = tagsObj.props.tagsArr
    console.log(tags)
    history.push(`/notas/detalhes/tags=${tags}`)
  }

  const tabContent = [
    {
      content: (
        <DataTable
          headCells={headCells}
          tableRows={tableRows}
          goToRowDetail={goToRowDetail}
          filterRows={['ids']}
          filterDataMobile={['modified']}
          unsortable
        />
      ),
    },
  ]

  useEffect(() => {
    getAllNotes().then(res => {
      setLists(res)
      setIsLoading(false)
    })
  }, [])

  return (
    <Default CustomHeader={PageHeader}>
      {!isLoading && tableRows.length === 0 ? (
        <EmptyStateWrapper>
          <Typography variant='body1' gutterBottom>
            Você não possui nenhuma nota. <br />
          </Typography>
        </EmptyStateWrapper>
      ) : (
        <TabsWrapper tabs={tabLabels} contents={tabContent} />
      )}
      <Snackbar
        open={snack.open}
        onClose={toggleSnack}
        autoHideDuration={2000}
        TransitionComponent={snack.Transition}
        message='Nota enviada para o seu whatsapp'
        key={snack.Transition.name}
      />
      <DeleteConfirmation
        dialogOpen={deleteDialogOpen}
        setDialogOpen={setDeleteDialogOpen}
        handleDelete={handleDelete}
        deleteTitle={'Deletar note?'}
      />
    </Default>
  )
}
