import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import DeleteConfirmation from 'components/DeleteConfirmation'
import DataTable from 'components/Table'
import TabsWrapper from 'components/Tabs'
import { localDateWithoutTime } from 'helpers/date'
import { getAllReminder, deleteReminderItem } from 'services/reminder'
import Default from 'templates/default'

import Actions from './components/Actions'
import PageHeader from './components/PageHeader'
import { headCells, tabLabels } from './helpers/tableLabels'
import { EmptyStateWrapper } from './styles'

// const mockData = [
// {
//     time: '14:30',
//     dueDate: '2021-08-31',
//     taskDescription: 'Médico Amanha...',
// },
// {
//     time: '16:30',
//     dueDate: '2021-08-28',
//     taskDescription: 'Finalizar task...',
// },
// ]

export default function Lists() {
  const [lists, setLists] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')

  const handleDelete = () => {
    setDeleteDialogOpen(false)
    console.log(idToDelete)
    const updatedReminder = lists.filter(item => item.id !== idToDelete)
    deleteReminderItem(idToDelete).then(() => {
      setLists(updatedReminder)
    })
  }

  const handleDeleteConfirmation = id => () => {
    setDeleteDialogOpen(true)
    setIdToDelete(id)
  }

  const tableRows = lists.map(({ time, dueDate, taskDescription, id }) => {
    return {
      time,
      dueDate: localDateWithoutTime(dueDate),
      taskDescription,
      action: <Actions exclude={handleDeleteConfirmation(id)} />,
    }
  })

  const tabContent = [
    {
      content: (
        <DataTable
          headCells={headCells}
          tableRows={tableRows}
          filterRows={['ids']}
          filterDataMobile={['modified']}
          unsortable
        />
      ),
    },
  ]

  useEffect(() => {
    getAllReminder().then(res => {
      setLists(res)
      setIsLoading(false)
    })
    // setLists(mockData)
    // setIsLoading(false)
  }, [])

  return (
    <Default CustomHeader={PageHeader}>
      {!isLoading && lists.length === 0 ? (
        <EmptyStateWrapper>
          <Typography variant='body1' gutterBottom>
            Você não possui nenhuma nota.
            <br />
          </Typography>
        </EmptyStateWrapper>
      ) : (
        <TabsWrapper tabs={tabLabels} contents={tabContent} />
      )}

      <DeleteConfirmation
        dialogOpen={deleteDialogOpen}
        setDialogOpen={setDeleteDialogOpen}
        handleDelete={handleDelete}
        deleteTitle={'Deletar note?'}
      />
    </Default>
  )
}
