import React from 'react'
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import DrawerContent from './components/DrawerContent'
import WithAuth from './components/WithAuth'
import GlobalStyle from './globalStyles'
import Dashboard from './modules/dashboard'
import ListDetails from './modules/listDetails'
import Lists from './modules/lists'
import Login from './modules/login'
import Media from './modules/media'
import MediaDetails from './modules/mediaDetails'
import NoteDetails from './modules/noteDetails'
import Notes from './modules/notes'
import QRCode from './modules/qrCode'
import Reminders from './modules/reminders'
import Wallet from './modules/wallet'
import store from './redux/store'

export default function App() {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <Router>
        <DrawerContent>
          <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/qrcode' component={QRCode} />
            <Route exact path='/dashboard' component={WithAuth(Dashboard)} />
            <Route
              exact
              path='/:number/:code'
              component={WithAuth(Dashboard)}
            />

            <Route exact path='/listas' component={WithAuth(Lists)} />
            <Route
              exact
              path='/listas/detalhes/:tags'
              component={ListDetails}
            />

            <Route exact path='/midias' component={WithAuth(Media)} />
            <Route
              exact
              path='/midias/detalhes/:name'
              component={MediaDetails}
            />

            <Route exact path='/notas' component={WithAuth(Notes)} />
            <Route exact path='/notas/detalhes/:tags' component={NoteDetails} />

            <Route exact path='/carteira' component={WithAuth(Wallet)} />

            <Route exact path='/lembretes' component={WithAuth(Reminders)} />
            <Redirect exact to='/' />
          </Switch>
        </DrawerContent>
      </Router>
    </Provider>
  )
}
