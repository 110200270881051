import React from 'react'

import { Container } from './styles'

export default function Footer() {
  return (
    <Container>
      <a href='https://olamaia.com/' rel='noreferrer'>
        olamaia.com
      </a>
    </Container>
  )
}
