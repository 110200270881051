import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import React from 'react'

function DialogWrapper({ children, title, isOpen, toggle, fullWidth }) {
  return (
    <Dialog
      fullWidth={fullWidth}
      open={isOpen}
      onClose={toggle}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
      {children}
    </Dialog>
  )
}

DialogWrapper.propTypes = {
  title: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default DialogWrapper
