import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import React, { useState } from 'react'

import DialogWrapper from 'components/Dialog'
import InputText from 'components/InputText'
import { addList } from 'services/lists'

import { themeOverWrites } from './styles'

const useStyles = makeStyles(themeOverWrites)

export default function PageHeader() {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  const onSubmit = values => {
    addList(values.newList).then(() => {
      window.location.reload()
    })
  }

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      newList: '',
    },
    onSubmit,
  })

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Typography variant='h6' gutterBottom>
        <span className={classes.emoji} role='img' aria-label='checkmark'>
          ✔
        </span>
        Listas
      </Typography>
      <DialogWrapper
        fullWidth
        isOpen={isOpen}
        toggle={toggle}
        title='Adicionar uma nova lista'
      >
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <InputText
              required
              id='newList'
              label='Digite o nome da nova lista'
              onChange={handleChange}
              defaultValue={values.newList}
              fullWidth
            />
            <DialogActions>
              <Button type='submit' color='primary'>
                Adicionar
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </DialogWrapper>
    </>
  )
}
