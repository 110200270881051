import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

export default function InputText({
  className,
  onChange,
  defaultValue,
  id,
  label,
  fullWidth,
  required,
}) {
  return (
    <TextField
      fullWidth={fullWidth}
      variant='outlined'
      className={className}
      required={required}
      id={id}
      name={id}
      label={label}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  )
}

InputText.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
}
