import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import PropTypes from 'prop-types'
import React from 'react'

export default function Actions({ exclude, sentToWhatsApp }) {
  return (
    <>
      <IconButton onClick={sentToWhatsApp} aria-label='send'>
        <WhatsAppIcon />
      </IconButton>
      <IconButton onClick={exclude} aria-label='delete'>
        <DeleteIcon />
      </IconButton>
    </>
  )
}

Actions.propTypes = {
  exclude: PropTypes.func,
  sentToWhatsApp: PropTypes.func,
}
