import { Badge, Snackbar, Typography } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import DeleteConfirmation from 'components/DeleteConfirmation'
import DataTable from 'components/Table'
import TabsWrapper from 'components/Tabs'
import { deleteList, getAllLists, sendList } from 'services/lists'
import Default from 'templates/default'

import { localDate } from '../../helpers/date'

import Actions from './components/Actions'
import PageHeader from './components/PageHeader'
import Tags from './components/Tags'
import { headCells, tabLabels } from './helpers/tableLabels'
import { EmptyStateWrapper } from './styles'

export default function Lists() {
  const history = useHistory()
  const [lists, setLists] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [tagsToDelete, setTagsToDelete] = useState('')
  const [snack, setSnack] = React.useState({
    open: false,
    Transition: Fade,
  })

  const toggleSnack = () => {
    setSnack(prevState => ({
      ...prevState,
      open: !snack.open,
    }))
  }

  const send = tags => () => {
    sendList(tags)
      .then(() => {
        toggleSnack()
      })
      .catch(console.log)
  }

  const handleDelete = () => {
    setDeleteDialogOpen(false)
    const updatedList = lists.filter(item => item.tags !== tagsToDelete)
    deleteList(tagsToDelete).then(() => {
      setLists(updatedList)
    })
  }

  const handleDeleteConfirmation = tags => () => {
    setDeleteDialogOpen(true)
    setTagsToDelete(tags)
  }

  const tableRows = lists.map(({ tags, updated_at, items: count, ids }) => {
    return {
      tags: <Tags tagsArr={tags} />,
      modified: localDate(updated_at),
      count: <Badge badgeContent={count} color='primary' />,
      action: (
        <Actions
          exclude={handleDeleteConfirmation(tags)}
          sentToWhatsApp={send(tags)}
        />
      ),
      ids,
    }
  })

  const goToRowDetail = ({ tags: tagsObj }) => () => {
    const tags = tagsObj.props.tagsArr
    history.push(`/listas/detalhes/tags=${tags}`)
  }

  const tabContent = [
    {
      content: (
        <DataTable
          headCells={headCells}
          tableRows={tableRows}
          goToRowDetail={goToRowDetail}
          filterRows={['ids']}
          filterDataMobile={['modified']}
          unsortable
        />
      ),
    },
  ]

  useEffect(() => {
    getAllLists().then(res => {
      setLists(res)
      setIsLoading(false)
    })
  }, [])

  return (
    <Default CustomHeader={PageHeader}>
      {!isLoading && lists.length === 0 ? (
        <EmptyStateWrapper>
          <Typography variant='body1' gutterBottom>
            Você não possui nenhuma lista. <br />
          </Typography>
        </EmptyStateWrapper>
      ) : (
        <TabsWrapper tabs={tabLabels} contents={tabContent} />
      )}
      <Snackbar
        open={snack.open}
        onClose={toggleSnack}
        autoHideDuration={2000}
        TransitionComponent={snack.Transition}
        message='Lista enviada para o seu whatsapp'
        key={snack.Transition.name}
      />
      <DeleteConfirmation
        dialogOpen={deleteDialogOpen}
        setDialogOpen={setDeleteDialogOpen}
        handleDelete={handleDelete}
        deleteTitle={'Deletar lista?'}
      />
    </Default>
  )
}
