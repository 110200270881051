import { dataIdentity } from 'helpers/identities'
import HttpService from 'services/HttpService'

const TAGS_URL = process.env.REACT_APP_TAGS
const FILES_URL = process.env.REACT_APP_FILES

export const getTagList = async () =>
  HttpService.withToken.get(TAGS_URL).then(dataIdentity).catch(console.log)

export const getTagFiles = tag =>
  HttpService.withToken
    .get(`${FILES_URL}/tagged?tags=${tag}`)
    .then(dataIdentity)

export const sendFiles = fileId =>
  HttpService.withToken
    .get(`${FILES_URL}/${fileId}/send`)
    .then(() => 'sent')
    .then(console.log)

export const excludeFile = fileId =>
  HttpService.withToken
    .delete(`${FILES_URL}/${fileId}`)
    .then(() => 'deleted')
    .catch(console.log)

export const addTagToFile = (fileId, tagName) =>
  HttpService.withToken
    .put(`${FILES_URL}/${fileId}/${tagName}`)
    .then(() => 'added')
    .catch(console.log)

export const removeTagFromFile = (fileId, tagName) =>
  HttpService.withToken
    .delete(`${FILES_URL}/${fileId}/${tagName}`)
    .then(() => 'added')
    .catch(console.log)
