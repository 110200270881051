import { Grid, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import { themeOverWrites } from './styles'

const useStyles = makeStyles(themeOverWrites)

export default function Default({
  children,
  pageTitle,
  unicodeEmoji,
  CustomHeader,
}) {
  const classes = useStyles()

  return (
    <Grid container justify='space-between' spacing={3}>
      <Grid item xs={12} sm={12}>
        <Grid container justify='space-between'>
          {CustomHeader ? (
            <CustomHeader />
          ) : (
            <Typography variant='h6' gutterBottom>
              <span className={classes.emoji} role='img' aria-label='checkmark'>
                {unicodeEmoji}
              </span>
              {pageTitle}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Paper className={classes.root}>{children}</Paper>
    </Grid>
  )
}

Default.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
  unicodeEmoji: PropTypes.string,
  CustomHeader: PropTypes.func,
}
