import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import DialogWrapper from 'components/Dialog'
import InputText from 'components/InputText'

import { addItem } from '../../../../services/lists'

import { Breadcrumb, TagContainer, themeOverWrites } from './styles'
const useStyles = makeStyles(themeOverWrites)

export default function PageHeader() {
  const classes = useStyles()
  const { id, tags: tagsParam } = useParams()
  const history = useHistory()

  const tags = Array.from(tagsParam.substr(5).split(','))

  const [isOpen, setIsOpen] = useState(false)

  const goBack = () => history.push('/listas')

  const onSubmit = ({ newItem }) => {
    addItem(id, newItem).then(() => {
      window.location.reload()
    })
  }

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      newItem: '',
    },
    onSubmit,
  })

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Breadcrumb>
        <Typography
          onClick={goBack}
          className={classes.goback}
          variant='h6'
          gutterBottom
        >
          <span className={classes.emoji} role='img' aria-label='checkmark'>
            ✔
          </span>
          Listas
        </Typography>
        <ArrowBackIosIcon fontSize='small' />
        {tags.map(tag => (
          <TagContainer key={tag}>{tag}</TagContainer>
        ))}
      </Breadcrumb>
      <DialogWrapper
        fullWidth
        isOpen={isOpen}
        toggle={toggle}
        title='Adicionar uma nova lista'
      >
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <InputText
              required
              id='newItem'
              label='Adicione um item na lista'
              onChange={handleChange}
              defaultValue={values.newItem}
              fullWidth
            />
            <DialogActions>
              <Button type='submit' color='primary'>
                Adicionar
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </DialogWrapper>
    </>
  )
}
