import { DialogActions, Snackbar } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import BreadcrumbHeader from 'components/BreadcrumbHeader'
import DeleteConfirmation from 'components/DeleteConfirmation'
import DialogWrapper from 'components/Dialog'
import DataTable from 'components/Table'
import TabsWrapper from 'components/Tabs'
import { excludeFile, getTagFiles, sendFiles } from 'services/tags'
import Default from 'templates/default'

import Actions from './components/Actions'
import FileTags from './components/FileTags'
import FileWrapper from './components/FileWrapper'
import Tags from './components/Tags'
import { FILE_TYPES, GetFileIcon } from './helpers'
import { headCells, tabLabels, filterRows } from './helpers/tableHelpers'

export default function MediaDetails() {
  const { name: tagName } = useParams()
  const [fileList, setFileList] = useState([])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')
  const [snack, setSnack] = React.useState({
    open: false,
    Transition: Fade,
  })
  const [isOpen, setIsOpen] = useState(false)
  const [dialogData, setDialogData] = useState({
    title: '',
    fileTags: [],
    fileSrc: '',
    fileId: '',
  })

  const toggleDialog = () => {
    if (!isOpen === false) {
      window.location.reload()
    }

    setIsOpen(!isOpen)
  }

  const toggleSnack = () => {
    setSnack(prevState => ({
      ...prevState,
      open: !snack.open,
    }))
  }

  const send = fileId => () => {
    sendFiles(fileId)
      .then(() => {
        toggleSnack()
      })
      .catch(console.log)
  }

  const handleDelete = () => {
    setIsOpen(false)
    setDeleteDialogOpen(false)
    excludeFile(idToDelete).then(() => {
      const updatedList = fileList.filter(item => item.id !== idToDelete)
      setFileList(updatedList)
    })
  }

  const filterRowsPerType = (type, rows) => {
    const filteredRows = filterRows(rows)

    const formattedRows = filteredRows[type].map(
      ({ type, id, created_at, file, tags, size }) => {
        return {
          type: GetFileIcon(type),
          tags: <Tags tagsArr={tags} />,
          created_at,
          size,
          file,
          id,
          action: (
            <Actions
              exclude={handleDeleteConfirmation(id)}
              sentToWhatsApp={send(id)}
            />
          ),
        }
      }
    )

    return formattedRows
  }
  const handleDeleteConfirmation = id => () => {
    setDeleteDialogOpen(true)
    setIdToDelete(id)
  }

  const showDetails = ({ id }) => () => {
    const { file: fileSrc, tags: fileTags, type: title } = fileList.find(
      item => item.id === id
    )
    setDialogData({
      fileId: id,
      fileSrc,
      fileTags,
      title,
    })
    toggleDialog()
  }

  const tabContent = [
    {
      content: (
        <DataTable
          goToRowDetail={showDetails}
          headCells={headCells}
          tableRows={filterRowsPerType('all', fileList)}
          filterRows={['id', 'file']}
          unsortable
        />
      ),
    },
    {
      content: (
        <DataTable
          goToRowDetail={showDetails}
          headCells={headCells}
          tableRows={filterRowsPerType('image', fileList)}
          filterRows={['id', 'file']}
          unsortable
        />
      ),
    },
    {
      content: (
        <DataTable
          goToRowDetail={showDetails}
          headCells={headCells}
          tableRows={filterRowsPerType('video', fileList)}
          filterRows={['id', 'file']}
          unsortable
        />
      ),
    },
    {
      content: (
        <DataTable
          goToRowDetail={showDetails}
          headCells={headCells}
          tableRows={filterRowsPerType('document', fileList)}
          filterRows={['id', 'file']}
          unsortable
        />
      ),
    },
    {
      content: (
        <DataTable
          goToRowDetail={showDetails}
          headCells={headCells}
          tableRows={filterRowsPerType('audio', fileList)}
          filterRows={['id', 'file']}
          unsortable
        />
      ),
    },
  ]

  useEffect(() => {
    getTagFiles(tagName).then(setFileList)
  }, [])

  return (
    <Default
      CustomHeader={() =>
        BreadcrumbHeader({
          backRoute: '/midias',
          icon: '📁',
          title: 'Mídias',
        })
      }
    >
      <TabsWrapper tabs={tabLabels} contents={tabContent} />
      <Snackbar
        open={snack.open}
        onClose={toggleSnack}
        TransitionComponent={snack.Transition}
        autoHideDuration={2000}
        message='Arquivo enviado para o seu whatsapp'
        key={snack.Transition.name}
      />
      <DeleteConfirmation
        dialogOpen={deleteDialogOpen}
        setDialogOpen={setDeleteDialogOpen}
        handleDelete={handleDelete}
        deleteTitle={'Deletar arquivo?'}
      />
      <DialogWrapper
        fullWidth
        isOpen={isOpen}
        toggle={toggleDialog}
        title={FILE_TYPES[dialogData.title]}
      >
        <FileTags fileId={dialogData.fileId} tags={dialogData.fileTags} />
        <FileWrapper
          fileSource={dialogData.fileSrc}
          fileType={dialogData.title}
        />
        <DialogActions>
          <Actions
            exclude={handleDeleteConfirmation(dialogData.fileId)}
            sentToWhatsApp={send(dialogData.fileId)}
          />
        </DialogActions>
      </DialogWrapper>
    </Default>
  )
}
