import { Typography } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'
import React, { useEffect, useState } from 'react'

import DeleteConfirmation from 'components/DeleteConfirmation'
import DataTable from 'components/Table'
import TabsWrapper from 'components/Tabs'
import { localDateString } from 'helpers/date'
import { getAllWallet, deleteWalletItem } from 'services/wallet'
import Default from 'templates/default'

import Actions from './components/Actions'
import PageHeader from './components/PageHeader'
import { headCells, tabLabels } from './helpers/tableLabels'
import { EmptyStateWrapper } from './styles'

export default function Lists() {
  const [lists, setLists] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')

  const handleDelete = () => {
    setDeleteDialogOpen(false)
    console.log(idToDelete)
    const updatedWalet = lists.filter(item => item.id !== idToDelete)
    deleteWalletItem(idToDelete).then(() => {
      setLists(updatedWalet)
    })
  }

  const handleDeleteConfirmation = id => () => {
    setDeleteDialogOpen(true)
    setIdToDelete(id)
  }

  const tableRows = lists.map(
    ({ transactionDate, value, title, balanceType, id }) => {
      return {
        iconType: balanceType === 'Saída' ? <ArrowBack /> : <ArrowForward />,
        value,
        title,
        balanceType,
        transactionDate: localDateString(transactionDate),
        action: <Actions exclude={handleDeleteConfirmation(id)} />,
      }
    }
  )

  const tabContent = [
    {
      content: (
        <DataTable
          headCells={headCells}
          tableRows={tableRows}
          filterRows={['ids']}
          filterDataMobile={['modified']}
          unsortable
        />
      ),
    },
  ]

  useEffect(() => {
    getAllWallet().then(res => {
      setLists(res)
      setIsLoading(false)
    })
  }, [])

  return (
    <Default CustomHeader={PageHeader}>
      {!isLoading && lists.length === 0 ? (
        <EmptyStateWrapper>
          <Typography variant='body1' gutterBottom>
            Você não possui nenhuma nota.
            <br />
          </Typography>
        </EmptyStateWrapper>
      ) : (
        <TabsWrapper tabs={tabLabels} contents={tabContent} />
      )}

      <DeleteConfirmation
        dialogOpen={deleteDialogOpen}
        setDialogOpen={setDeleteDialogOpen}
        handleDelete={handleDelete}
        deleteTitle={'Deletar note?'}
      />
    </Default>
  )
}
