import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  padding: 0 16px;
`

export const UserName = styled.div`
  background: none;
  border: solid 1px #fff;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 0 16px;
`

export const Divider = styled.div`
  background: #fff;
  height: 40px;
  margin: 0 21px;
  width: 1px;
`

export const Logout = styled.div`
  &:hover {
    cursor: pointer;
  }
`

export const LogoutText = styled.p`
  margin: 0;
`
