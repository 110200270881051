import PropTypes from 'prop-types'
import React from 'react'

function Logo({ onClick }) {
  return (
    <svg
      onClick={onClick}
      width='107'
      height='32'
      viewBox='0 0 107 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.9451 14.3145C10.6117 14.3145 10.2858 14.4133 10.0086 14.5985C9.73139 14.7838 9.51533 15.047 9.38775 15.3551C9.26016 15.6631 9.22678 16.002 9.29182 16.329C9.35686 16.656 9.51741 16.9563 9.75316 17.1921C9.9889 17.4278 10.2893 17.5884 10.6163 17.6534C10.9432 17.7185 11.2822 17.6851 11.5902 17.5575C11.8982 17.4299 12.1615 17.2139 12.3467 16.9367C12.5319 16.6594 12.6308 16.3335 12.6308 16.0001C12.6307 15.5531 12.4531 15.1244 12.137 14.8083C11.8209 14.4922 11.3922 14.3145 10.9451 14.3145Z'
        fill='white'
      />
      <path
        d='M16.001 14.3145C15.6676 14.3145 15.3417 14.4133 15.0644 14.5985C14.7872 14.7838 14.5712 15.047 14.4436 15.3551C14.316 15.6631 14.2826 16.002 14.3477 16.329C14.4127 16.656 14.5733 16.9563 14.809 17.1921C15.0447 17.4278 15.3451 17.5884 15.6721 17.6534C15.9991 17.7185 16.338 17.6851 16.646 17.5575C16.9541 17.4299 17.2173 17.2139 17.4026 16.9367C17.5878 16.6594 17.6866 16.3335 17.6866 16.0001C17.6866 15.5531 17.5089 15.1244 17.1928 14.8083C16.8767 14.4922 16.448 14.3145 16.001 14.3145Z'
        fill='white'
      />
      <path
        d='M21.0604 14.3145C20.727 14.3145 20.4011 14.4133 20.1239 14.5985C19.8467 14.7838 19.6306 15.047 19.503 15.3551C19.3755 15.6631 19.3421 16.002 19.4071 16.329C19.4722 16.656 19.6327 16.9563 19.8684 17.1921C20.1042 17.4278 20.4046 17.5884 20.7315 17.6534C21.0585 17.7185 21.3975 17.6851 21.7055 17.5575C22.0135 17.4299 22.2768 17.2139 22.462 16.9367C22.6472 16.6594 22.7461 16.3335 22.7461 16.0001C22.746 15.5531 22.5684 15.1244 22.2523 14.8083C21.9362 14.4922 21.5075 14.3145 21.0604 14.3145Z'
        fill='white'
      />
      <path
        d='M16.0021 1.80571e-07C13.4032 -0.000346602 10.8433 0.632415 8.54378 1.84356C6.24429 3.05471 4.27444 4.80778 2.8046 6.95117C1.33476 9.09456 0.409169 11.5637 0.10788 14.1452C-0.193409 16.7266 0.138671 19.3426 1.0754 21.7668C2.01213 24.1911 3.52532 26.3507 5.48409 28.0588C7.44286 29.767 9.78825 30.9723 12.3174 31.5704C14.8466 32.1686 17.4834 32.1417 19.9999 31.492C22.5163 30.8423 24.8366 29.5893 26.76 27.8415C27.237 28.8265 27.9817 29.6573 28.9088 30.2388C29.8359 30.8204 30.908 31.1291 32.0024 31.1297V16C32.0027 13.8987 31.589 11.818 30.785 9.87667C29.9811 7.93532 28.8025 6.17137 27.3167 4.68557C25.8308 3.19977 24.0669 2.02124 22.1255 1.21728C20.1841 0.413319 18.1034 -0.000315513 16.0021 1.80571e-07ZM26.1865 26.2532C26.1868 26.3172 26.1678 26.3798 26.1319 26.4328C26.096 26.4858 26.0449 26.5268 25.9854 26.5503C25.9258 26.5738 25.8606 26.5788 25.7982 26.5646C25.7357 26.5504 25.6791 26.5176 25.6355 26.4707L22.8939 23.4929C21.173 25.074 18.9606 26.0148 16.628 26.1575C14.2955 26.3002 11.9848 25.6361 10.0841 24.2766C8.18329 22.9171 6.80812 20.9451 6.18942 18.6915C5.57073 16.438 5.74619 14.0402 6.68634 11.9008C7.6265 9.76134 9.2741 8.01048 11.3525 6.94218C13.431 5.87387 15.8137 5.55317 18.1006 6.03391C20.3875 6.51465 22.4394 7.76757 23.9118 9.58231C25.3842 11.397 26.1874 13.6631 26.1865 16V26.2532Z'
        fill='white'
      />
      <path
        d='M56.0604 7.99757C55.0831 7.99611 54.1179 8.21423 53.2361 8.63584C52.3544 9.05745 51.5786 9.67176 50.9661 10.4334C50.3543 9.67227 49.5793 9.05824 48.6984 8.63665C47.8175 8.21505 46.8532 7.99665 45.8766 7.99757C44.4178 7.99757 42.9024 8.71077 41.7007 9.94732C41.4319 9.56897 41.0764 9.26052 40.6639 9.04779C40.2514 8.83506 39.7939 8.72423 39.3298 8.72459V23.4604H42.2439V14.5459C42.2439 13.5818 42.6269 12.6572 43.3086 11.9755C43.9903 11.2938 44.9149 10.9108 45.879 10.9108C46.8431 10.9108 47.7677 11.2938 48.4495 11.9755C49.1312 12.6572 49.5142 13.5818 49.5142 14.5459V23.4604H52.4226V14.5459C52.4227 13.5806 52.8063 12.6549 53.4889 11.9723C54.1715 11.2897 55.0972 10.9061 56.0625 10.906C57.0271 10.9072 57.9517 11.2912 58.6333 11.9737C59.3149 12.6562 59.6977 13.5814 59.6976 14.5459V20.5523C59.6975 20.9343 59.7726 21.3125 59.9187 21.6655C60.0648 22.0184 60.279 22.3391 60.5491 22.6092C60.8193 22.8793 61.1399 23.0935 61.4929 23.2396C61.8458 23.3857 62.2241 23.4609 62.6061 23.4607V14.5459C62.6062 13.6861 62.4371 12.8348 62.1082 12.0404C61.7794 11.246 61.2973 10.5241 60.6895 9.91605C60.0817 9.30797 59.36 8.82558 58.5658 8.4964C57.7715 8.16723 56.9202 7.99772 56.0604 7.99757Z'
        fill='white'
      />
      <path
        d='M98.2439 7.99756C96.9443 7.99717 95.6643 8.31336 94.5144 8.91879C93.3645 9.52422 92.3794 10.4007 91.6443 11.4723C90.9092 12.544 90.4463 13.7785 90.2955 15.0693C90.1447 16.3601 90.3106 17.6681 90.7789 18.8803C91.2472 20.0926 92.0037 21.1725 92.9831 22.0266C93.9625 22.8808 95.1352 23.4835 96.3998 23.7827C97.6644 24.0819 98.9829 24.0684 100.241 23.7436C101.499 23.4187 102.66 22.7923 103.621 21.9183C103.86 22.4108 104.232 22.8262 104.696 23.1169C105.159 23.4076 105.695 23.562 106.243 23.5622V16C106.243 14.9493 106.036 13.9089 105.634 12.9381C105.232 11.9674 104.643 11.0852 103.901 10.3422C103.158 9.59906 102.276 9.00954 101.305 8.60726C100.335 8.20497 99.2945 7.9978 98.2439 7.99756ZM103.333 21.1261C103.333 21.1582 103.323 21.1896 103.305 21.216C103.287 21.2424 103.261 21.2627 103.231 21.2742C103.201 21.2858 103.168 21.288 103.137 21.2806C103.105 21.2732 103.077 21.2565 103.055 21.2328L101.689 19.7466C100.829 20.5384 99.7222 21.0102 98.5551 21.0825C97.3881 21.1549 96.2316 20.8234 95.2801 20.1439C94.3285 19.4644 93.6397 18.4781 93.3295 17.3507C93.0192 16.2233 93.1062 15.0235 93.5761 13.9528C94.0459 12.882 94.8698 12.0055 95.9096 11.4705C96.9493 10.9355 98.1414 10.7745 99.2858 11.0146C100.43 11.2547 101.457 11.8813 102.194 12.7891C102.931 13.6969 103.333 14.8307 103.333 16L103.333 21.1261Z'
        fill='white'
      />
      <path
        d='M73.5144 7.99756C72.2149 7.99717 70.9348 8.31336 69.7849 8.91879C68.6351 9.52422 67.65 10.4007 66.9149 11.4723C66.1798 12.544 65.7168 13.7785 65.566 15.0693C65.4153 16.3601 65.5812 17.6681 66.0495 18.8803C66.5178 20.0926 67.2743 21.1725 68.2537 22.0266C69.233 22.8808 70.4058 23.4835 71.6704 23.7827C72.935 24.0819 74.2535 24.0684 75.5118 23.7436C76.7701 23.4187 77.9303 22.7923 78.892 21.9183C79.1305 22.4108 79.5029 22.8262 79.9664 23.1169C80.43 23.4076 80.9661 23.562 81.5132 23.5622V16C81.5135 14.9493 81.3068 13.9089 80.9049 12.9381C80.5031 11.9674 79.914 11.0852 79.1712 10.3422C78.4284 9.59906 77.5466 9.00954 76.576 8.60726C75.6054 8.20497 74.5651 7.9978 73.5144 7.99756ZM78.6039 21.1261C78.6041 21.1578 78.5946 21.1889 78.5767 21.2151C78.5589 21.2413 78.5335 21.2615 78.504 21.2731C78.4744 21.2846 78.4421 21.287 78.4112 21.2798C78.3803 21.2726 78.3523 21.2562 78.3308 21.2328L76.9597 19.7466C76.0994 20.5384 74.9927 21.0102 73.8257 21.0825C72.6586 21.1549 71.5022 20.8234 70.5507 20.1439C69.5991 19.4644 68.9103 18.4781 68.6 17.3507C68.2897 16.2233 68.3768 15.0235 68.8466 13.9528C69.3165 12.882 70.1404 12.0055 71.1801 11.4705C72.2199 10.9355 73.412 10.7745 74.5564 11.0146C75.7008 11.2547 76.7277 11.8813 77.4647 12.7891C78.2016 13.6969 78.6038 14.8307 78.6036 16L78.6039 21.1261Z'
        fill='white'
      />
      <path
        d='M84.425 14.5504V20.807C84.4249 21.2011 84.5024 21.5914 84.6532 21.9556C84.8039 22.3197 85.025 22.6506 85.3037 22.9293C85.5824 23.2079 85.9132 23.429 86.2774 23.5798C86.6415 23.7305 87.0318 23.8081 87.4259 23.8079V11.5449C86.6295 11.5458 85.866 11.8629 85.3033 12.4264C84.7406 12.99 84.4247 13.754 84.425 14.5504Z'
        fill='white'
      />
      <path
        d='M84.425 8.1875V11.545H87.4259V11.1884C87.426 10.7943 87.3485 10.404 87.1977 10.0399C87.047 9.67575 86.8259 9.34488 86.5472 9.06619C86.2685 8.7875 85.9377 8.56646 85.5735 8.41569C85.2094 8.26492 84.8191 8.18738 84.425 8.1875Z'
        fill='white'
      />
    </svg>
  )
}

Logo.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default Logo
