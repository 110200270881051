import { Button, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  themeOverWrites,
  GoBackContainer,
  ImageContainer,
  Content,
} from './styles'

const useStyles = makeStyles(themeOverWrites)

const QRCodeImg = require(`assets/${process.env.REACT_APP_NUMBER}.svg`).default

const wppUrl =
  'https://api.whatsapp.com/send?phone=' +
  process.env.REACT_APP_NUMBER +
  '&text=Quero%20o%20c%C3%B3digo%20para%20acessar%20o%20WebApp.'

export default function QRCodeBox() {
  const classes = useStyles()
  const { goBack } = useHistory()

  return (
    <Paper className={classes.paper}>
      <GoBackContainer onClick={goBack}>
        <ArrowBackIosIcon />
        voltar
      </GoBackContainer>

      <p className={classes.text}>
        QRcode para acessar o Whatsapp e pegar o seu código de acesso.
      </p>

      <Content>
        <ImageContainer>
          <img src={QRCodeImg} alt='Imagem código QR' />
        </ImageContainer>

        <p>OU</p>

        <a href={wppUrl} target='_blank' rel='noreferrer'>
          Pegar o código no Whatsapp
        </a>

        <Button
          className={classes.button}
          type='button'
          variant='contained'
          color='primary'
          onClick={goBack}
        >
          JÁ ESTOU COM O CÓDIGO
        </Button>
      </Content>
    </Paper>
  )
}
