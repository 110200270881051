import React from 'react'

import Default from 'templates/default'

import NoteItems from './components/NoteItems'
import PageHeader from './components/PageHeader'

export default function ListDetails() {
  return (
    <Default CustomHeader={PageHeader}>
      <NoteItems />
    </Default>
  )
}
