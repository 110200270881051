import styled from 'styled-components'

export const Item = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  text-decoration: ${({ done }) => (done ? 'line-through' : '')};
  list-style: none;
  padding: 22px 30px;

  &:hover {
    background: rgba(109, 30, 255, 0.04);
  }

  &:last-of-type {
    border: 0;
  }

  .text {
    margin-left: 10px;
  }

  button:first-of-type {
    margin-left: auto;
  }

  span {
    max-width: 70vw;
  }
`
