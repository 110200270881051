import { Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { themeOverWrites } from './styles'

const useStyles = makeStyles(() => themeOverWrites())

const dashItems = [
  {
    label: 'Mídias',
    icon: '📂️',
    url: '/midias',
  },
  {
    label: 'Listas',
    icon: '✔️',
    url: '/listas',
  },
  {
    label: 'Notas',
    icon: '📝',
    url: '/notas',
  },
  {
    label: 'Carteira',
    icon: '💰',
    url: '/carteira',
  },
  {
    label: 'Lembretes',
    icon: '⏰',
    url: '/lembretes',
  },
]

export default function Dashboard() {
  const classes = useStyles()
  const history = useHistory()

  const goTo = url => history.push(url)

  return (
    <Grid container justify='flex-start' spacing={3}>
      <Grid item xs={12} sm={12}>
        <Typography variant='h6' gutterBottom>
          <span className={classes.emoji} role='img' aria-label='house'>
            🏠
          </span>
          Dashboard
        </Typography>
      </Grid>
      {dashItems.map(item => {
        return (
          <Grid item xs={12} sm={4} key={item.label}>
            <Paper onClick={() => goTo(item.url)} className={classes.gridItem}>
              {item.icon} {item.label}
            </Paper>
          </Grid>
        )
      })}
    </Grid>
  )
}
