import styled from 'styled-components'

export const themeOverWrites = () => ({
  root: {
    flexGrow: 1,
  },
  emoji: {
    marginRight: '10px',
  },
  goback: {
    filter: 'grayscale()',
    cursor: 'pointer',
  },
})

export const Breadcrumb = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  font-weight: normal;

  svg {
    margin-left: 10px;
  }
`

export const TagContainer = styled.div`
  background-color: lightgray;
  margin-right: 8px;
  padding: 4px 8px;
  border-radius: 8px;
`
