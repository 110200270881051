import { Dialog, DialogActions, DialogTitle, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

function DeleteConfirmation({
  dialogOpen,
  setDialogOpen,
  handleDelete,
  deleteTitle = 'Deletar?',
}) {
  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        setDialogOpen(false)
      }}
      aria-labelledby='delete-dialog-container'
    >
      <DialogTitle id='delete-dialog-title'>{deleteTitle}</DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            setDialogOpen(false)
          }}
          color='primary'
        >
          Cancelar
        </Button>
        <Button onClick={handleDelete} color='primary'>
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteConfirmation.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  deleteTitle: PropTypes.string,
}

export default DeleteConfirmation
