import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { getComparator, stableSort } from './helpers'
import { themeOverWrites } from './styles'
import { EnhancedTableHead, EnhancedUnsortableTableHead } from './TableHead'

const useStyles = makeStyles(themeOverWrites)

function DataTable({
  tableRows,
  headCells,
  goToRowDetail,
  filterRows = [],
  filterDataMobile = [],
  unsortable,
}) {
  const mobile = useMediaQuery('(max-width:600px)')
  const [page, setPage] = React.useState(0)
  const [filteredRows, setFilteredRows] = React.useState([...filterRows])
  const [filteredHeadCells, setFilteredHeadCell] = React.useState(headCells)

  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('name')

  const emptyRows = 5 - Math.min(5, tableRows.length - page * 5)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  useEffect(() => {
    if (mobile) {
      setFilteredRows([...filterRows, ...filterDataMobile])
      var filtered = headCells.filter(function (item) {
        return this.indexOf(item.id) < 0
      }, filterDataMobile)
      setFilteredHeadCell(filtered)
    } else {
      setFilteredRows([...filterRows])
      setFilteredHeadCell(headCells)
    }
  }, [mobile])

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
        >
          {unsortable ? (
            <EnhancedUnsortableTableHead headCells={filteredHeadCells} />
          ) : (
            <EnhancedTableHead
              headCells={filteredHeadCells}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
          )}
          <TableBody>
            {stableSort(tableRows, getComparator(order, orderBy))
              .slice(page * 5, page * 5 + 5)
              .map(row => {
                const keys = Object.keys(row).filter(
                  item => !filteredRows.includes(item)
                )
                return (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={uuidv4()}
                  >
                    {keys.map(item => {
                      return (
                        <TableCell
                          onClick={
                            item !== 'action' && goToRowDetail
                              ? goToRowDetail({
                                  id: row.id,
                                  tags: row.tags,
                                })
                              : () => {}
                          }
                          align={item === 'action' ? 'right' : 'left'}
                          key={uuidv4()}
                        >
                          {row[item]}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 80 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                count={tableRows.length}
                rowsPerPage={5}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}

DataTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  tableRows: PropTypes.array.isRequired,
  goToRowDetail: PropTypes.func,
  filterRows: PropTypes.array,
  filterDataMobile: PropTypes.array,
  unsortable: PropTypes.bool,
}

export default DataTable
