import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'
import React from 'react'

import Actions from '../Actions'

import { Item } from './styles'

function ListItem({ name, id, done, update, exclude }) {
  return (
    <Item done={done}>
      <Checkbox onClick={() => update(id, !done)} checked={done} name={name} />
      <span className='text'>{name}</span>
      <Actions exclude={exclude} />
    </Item>
  )
}

ListItem.propTypes = {
  name: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  exclude: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  done: PropTypes.bool.isRequired,
}

export default ListItem
