export const ZINDEX_LEVELS = {
  LOW: '10',
  INTERMEDIATE: '50',
  HIGH: '100',
  OVERLAY: '999',
  CUSTOM: value => {
    if (Object.values(ZINDEX_LEVELS).includes(value)) {
      console.error('We already have this value on ZINDEX_LEVELS enum!')
      return
    }
    return value
  },
}
