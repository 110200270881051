import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { themeOverWrites, BreadCrumb, BreadCrumbItem } from './styles'

const useStyles = makeStyles(themeOverWrites)

function BreadcrumbHeader({ backRoute, icon, title }) {
  const classes = useStyles()
  const { name } = useParams()
  const { push } = useHistory()

  const goTo = () => push(backRoute)

  return (
    <BreadCrumb>
      <BreadCrumbItem onClick={goTo}>
        <Typography variant='h6' gutterBottom>
          <span className={classes.emoji} role='img' aria-label='folder'>
            {icon}
          </span>
          {title}
        </Typography>
      </BreadCrumbItem>
      <BreadCrumbItem marginLeft>
        <ArrowBackIosIcon fontSize='small' />
      </BreadCrumbItem>
      <BreadCrumbItem>
        <Typography variant='h6' gutterBottom>
          {name}
        </Typography>
      </BreadCrumbItem>
    </BreadCrumb>
  )
}

BreadcrumbHeader.propTypes = {
  backRoute: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default BreadcrumbHeader
