import axios from 'axios'
import clientSideCookies from 'js-cookie'

const withToken = axios.create()
const withoutToken = axios.create()

withToken.interceptors.request.use(config => {
  const token = clientSideCookies.get('access_token')

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

export default {
  withToken,
  withoutToken,
}
