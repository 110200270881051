import PropTypes from 'prop-types'
import React from 'react'

import Actions from '../Actions'

import { Item } from './styles'

function NoteItem({ note, edit, exclude }) {
  return (
    <Item>
      <span className='text'>{note}</span>
      <Actions exclude={exclude} edit={edit} />
    </Item>
  )
}

NoteItem.propTypes = {
  note: PropTypes.string.isRequired,
  edit: PropTypes.func.isRequired,
  exclude: PropTypes.func.isRequired,
}

export default NoteItem
